import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { DropdownSelect, MultiselectDropdown, StyledTextField } from "../../../../app_utils/input_fields";
import { generateAge } from "../../../../common_utils/utils";
import { produce } from "immer";
import { CSSTransition } from "react-transition-group";
import { Button, Grid, Typography } from "@mui/material";
import { annual_income_english, height } from "../../../../data/data";
import { AGE_FROM_P_LBL, AGE_FROM_P_VAL, AGE_TO_P_LBL, AGE_TO_P_VAL, ANNUAL_INCOME_P_VAL, BODY_TYPE_P_VAL, CASTE_P_LBL, CASTE_P_VAL, CITY_P_VAL, DIET_TYPE_P_VAL, DRINK_TYPE_P_VAL, EDUCATION_P_VAL, FAMILY_VALUE_P_VAL, HEIGHT_FROM_P_LBL, HEIGHT_FROM_P_VAL, HEIGHT_TO_P_LBL, HEIGHT_TO_P_VAL, MARITAL_STATUS_P_LBL, MARITAL_STATUS_P_VAL, OCCUPATION_P_VAL, PROFILE_ID, SKINTONE_P_VAL, SMOKE_TYPE_P_VAL, STATE_P_VAL, SUBCASTE_P_LBL, SUBCASTE_P_VAL, TRANSLATED_LANG, USER_ID } from "../../../../common_utils/constants";
import "../../../../styles/animationfade.css";
import { findByRole } from "@testing-library/react";
import RotatingHeart from "../../../../common_utils/loading_animator";
import { useLocation, useNavigate } from "react-router-dom";
import { usePartnerPrefsAdminContext } from "../../../contexts_admin/members_context/partnerPrefsContextAdmin";
import Prefernce_filter from "../../../../pages/partner_prefs/preference_filter";
import React from "react";




function PartnerPrefsAdmin() {


    const { data_partner_prefs_admin , loading, error, data_city_multiple_admin, fetch_city_multipleAdmin , partner_insert_admin, partner_update_admin
        , submit_partner_prefs_detailsAdmin, update_partner_prefs_detailsAdmin ,fetchPartnerPrefsItemsAdmin } = usePartnerPrefsAdminContext();
        
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();
    const [ageRange, setAgeRange] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const [occupationList, setOccupation] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [caste, setCaste] = useState([]);
    const [subcaste, setsubCaste] = useState([]);
    const [skintone, setskintone] = useState([]);
    const [diet, setdiet] = useState([]);
    const [family_value, setFamilyValue] = useState([]);
    const [annual_income, setannualIncome] = useState([]);
    const [body_type, setBodytype] = useState([]);
    const [smoke_type, setSmoketype] = useState([]);
    const [drink_type, setDrinktype] = useState([]);
    const [state_list_comma, setStateComma] = useState("");
    const [initial , setInitial] = useState(false);

    const [open, setopen] = useState(false);
    const [load, loadset] = useState(true);

    const handleClose = () => {

        setopen(false);
    }


    const state_lngwise = (state) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        }


    }

    const city_lngwise = (city) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        }


    }

    const degree_lngwise = (degree) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        }


    }

    const occupation_lngwise = (occupation) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        }


    }


    const location = useLocation();


    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');

       
        fetchPartnerPrefsItemsAdmin({
                "userId":userId,
                "communityId":communityId,
                "original": "en",
                "translate": ["en"]
            });
        
      }, [location]);


    useEffect(() => {


        setAnimated(true);
        setAgeRange(generateAge(18, 70));

        if (data_partner_prefs_admin.length > 0) {

            state_lngwise(data_partner_prefs_admin[2]);
            city_lngwise(data_partner_prefs_admin[3]);
            degree_lngwise(data_partner_prefs_admin[4]);
            occupation_lngwise(data_partner_prefs_admin[6]);

            const marital_list = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[7]).map(key => ({
                label: data_partner_prefs_admin[7][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[7][key].value         // Adjust based on your data structure
            }))];

            const caste = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[8]).map(key => ({
                label: data_partner_prefs_admin[8][key].caste,  // Adjust based on your data structure
                value: data_partner_prefs_admin[8][key].Id         // Adjust based on your data structure
            }))];

            const subcaste = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[9]).map(key => ({
                label: data_partner_prefs_admin[9][key].subcaste,  // Adjust based on your data structure
                value: data_partner_prefs_admin[9][key].Id         // Adjust based on your data structure
            }))];

            const skintone = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[10]).map(key => ({
                label: data_partner_prefs_admin[10][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[10][key].value         // Adjust based on your data structure
            }))];

            const diet_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[11]).map(key => ({
                label: data_partner_prefs_admin[11][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[11][key].value         // Adjust based on your data structure
            }))];

            const family_value = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[12]).map(key => ({
                label: data_partner_prefs_admin[12][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[12][key].value         // Adjust based on your data structure
            }))];

            const body_value = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[13]).map(key => ({
                label: data_partner_prefs_admin[13][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[13][key].value         // Adjust based on your data structure
            }))];

            const drink_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[14]).map(key => ({
                label: data_partner_prefs_admin[14][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[14][key].value         // Adjust based on your data structure
            }))];

            const smoke_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[15]).map(key => ({
                label: data_partner_prefs_admin[15][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[15][key].value         // Adjust based on your data structure
            }))];

            const annual_income = [{ label: 'Any', value: 'Any' }, ...Object.keys(data_partner_prefs_admin[16]).map(key => ({
                label: data_partner_prefs_admin[16][key].label,  // Adjust based on your data structure
                value: data_partner_prefs_admin[16][key].value         // Adjust based on your data structure
            }))];



            setMaritalStatus(marital_list);
            setCaste(caste);
            setsubCaste(subcaste);
            setskintone(skintone);
            setdiet(diet_type);
            setFamilyValue(family_value);
            setBodytype(body_value);
            setDrinktype(drink_type);
            setSmoketype(smoke_type);
            setannualIncome(annual_income);

            


            const setup = data_partner_prefs_admin[0]["0"];
            if (setup != undefined && initial == false) {
                const nextState = produce((draft) => {

                    setStateComma(setup.state);

                    draft["age_from"] = setup.age_range.split("-")[0];
                    draft["age_to"] = setup.age_range.split("-")[1];
                    draft["height_from"] = setup.height_range.split("-")[0];
                    draft["height_to"] = setup.height_range.split("-")[1];
                    draft["marital_status"] = setup.marital_status;
                    draft["caste"] = setup.caste;
                    draft["subcaste"] = setup.subcaste;
                    draft["skintone"] = setup.skintone;
                    draft["state"] = setup.state;
                    draft["city"] = setup.city;
                    draft["education"] = setup.education;
                    draft["occupation"] = setup.occupation;
                    draft["diet_type"] = setup.diet_type;
                    draft["family_value"] = setup.family_value;
                    draft["annual_income"] = setup.annual_income;
                    draft["body_type"] = setup.body_type;
                    draft["smoke_type"] = setup.smoke_type;
                    draft["drink_type"] = setup.drink_type;
                    draft["detail"] = setup.partner_details;

                });

                setPartnerPrefsField(nextState);

            }


        }


        if (data_city_multiple_admin.length > 0) {
           city_lngwise(data_city_multiple_admin);
        }


    }, [data_partner_prefs_admin , data_city_multiple_admin ]);

    useEffect(()=>{

        if (state_list_comma != "") {


            setTimeout(() => {
                

            fetch_city_multipleAdmin({
                "Ids": state_list_comma,
                "original": "en",
                "translate": ["en"]
            });

              }, 1500);
            
            
        
        }

    },[state_list_comma]);



    const navigate = useNavigate();
     useEffect(() => {

        if (partner_insert_admin.affectedRows >= 1 || partner_update_admin.success == 1) {


            setTimeout(() => {

                navigate('/docs', { replace: true });
            }, 1000);
        }

    }, [partner_insert_admin, partner_update_admin]);



    const [partnerPrefField, setPartnerPrefsField] = useState({
        age_from: '',
        age_to: '',
        height_from: '',
        height_to: '',
        marital_status: '',
        caste: '',
        subcaste: '',
        skintone: '',
        state: '',
        city: '',
        education: '',
        occupation: '',
        diet_type: '',
        family_value: '',
        annual_income: '',
        body_type: "",
        smoke_type: "",
        drink_type: "",
        detail: ""
    });


    const [errors, setErrors] = useState({
        age_from: false,
        age_to: false,
        height_from: false,
        height_to: false,
        marital_status: false,
        caste: false,
        subcaste: false,
        skintone: false,
        state: false,
        city: false,
        education: false,
        occupation: false,
        diet_type: false,
        family_value: false,
        annual_income: false,
        body_type: false,
        smoke_type: false,
        drink_type: false,
        detail: false
    });


    const [hasBeenFocused, setHasBeenFocused] = useState({
        age_from: false,
        age_to: false,
        height_from: false,
        height_to: false,
        marital_status: false,
        caste: false,
        subcaste: false,
        skintone: false,
        state: false,
        city: false,
        education: false,
        occupation: false,
        diet_type: false,
        family_value: false,
        annual_income: false,
        body_type: false,
        smoke_type: false,
        drink_type: false,
        detail: false
    });

    const handleChange = (fieldName) => (event , value) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {

            if (fieldName == "marital_status" || fieldName == "skintone" || fieldName == "state" || fieldName == "city" ||
                fieldName == "education" || fieldName == "occupation" || fieldName == "diet_type" || fieldName == "family_value"
                || fieldName == "body_type" || fieldName == "smoke_type" || fieldName == "drink_type"){

                draft[fieldName] = value.map(lang => lang.value).join(",");

            } else {

                draft[fieldName] = newValue;
            }
        });

        setPartnerPrefsField(nextState);

        if (fieldName == "state") {

            setInitial(true);
            setStateComma(value.map(lang => lang.value).join(","));
         }


        // Set the updated state

    };

    
    const preference_click = () => {
        setopen(true);
    };


    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;

        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);



                if (fieldName != "age_from" && fieldName != "age_to" && fieldName != "height_from" && fieldName != "height_to"
                    && fieldName != "marital_status") {

                    draftErrors[fieldName] = false;

                } else {

                    if (partnerPrefField[fieldName] !== '') {
                        draftErrors[fieldName] = false;
                    } else {
                        draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                    }

                }


            })
        );

    }




    const handleSubmit = () => {



        const newErrors = produce(errors, (draftErrors) => {
            for (const field in partnerPrefField) {
                const trimmedValue = partnerPrefField[field];

                if (field != "age_from" && field != "age_to" && field != "height_from" && field != "height_to"
                    && field != "marital_status") {
                    draftErrors[field] = false;

                }

                draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
            }

            draftErrors["marital_status"] = false;
                draftErrors["caste"] = false;
                draftErrors["subcaste"] = false;
                draftErrors["skintone"] = false;
                draftErrors["state"] = false;
                draftErrors["city"] = false;
                draftErrors["education"] = false;
                draftErrors["occupation"] = false;
                draftErrors["diet_type"] = false;
                draftErrors["family_value"] = false;
                draftErrors["annual_income"] = false;
                draftErrors["body_type"] = false;
                draftErrors["drink_type"] = false;
            
                draftErrors["detail"] = false;
                
        });

        setErrors(newErrors);




       // if (Object.values(newErrors).some(error => error) == true) {



       // } else {

            const searchParams = new URLSearchParams(location.search);
            const userId = searchParams.get('userId');
            const communityId = searchParams.get('communityId');
            const profile_id = searchParams.get('profile_id');


            loadset(false);

            const passdata = {
                "age_range": partnerPrefField.age_from + "-" + partnerPrefField.age_to,
                "height_range": partnerPrefField.height_from + "-" + partnerPrefField.height_to,
                "marital_status": partnerPrefField.marital_status,
                "caste": partnerPrefField.caste,
                "subcaste": partnerPrefField.subcaste,
                "skintone": partnerPrefField.skintone,
                "state": partnerPrefField.state,
                "city": partnerPrefField.city,
                "education": partnerPrefField.education,
                "occupation": partnerPrefField.occupation,
                "diet_type": partnerPrefField.diet_type,
                "family_value": partnerPrefField.family_value,
                "annual_income": partnerPrefField.annual_income,
                "body_type": partnerPrefField.body_type,
                "smoke_type": partnerPrefField.smoke_type,
                "drink_type": partnerPrefField.drink_type,
                "partner_details": partnerPrefField.detail,
                "userId": userId,
                "communityId":communityId,
                "profileId": profile_id, 
                "Id": data_partner_prefs_admin[0]["0"] == undefined ? "0" : data_partner_prefs_admin[0]["0"].Id
            }


            if (data_partner_prefs_admin[0]["0"] == null || data_partner_prefs_admin[0]["0"] == undefined) {
                submit_partner_prefs_detailsAdmin(passdata);
            } else {
                update_partner_prefs_detailsAdmin(passdata);
            }





       // }
       
    }



    return (
        <>

            {load == false ? <RotatingHeart /> :
                <div style={{ display: 'grid', placeItems: 'center', position: "absolute", top: "55px", width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "40px" , zIndex:"2" }}>

                    <div style={{ backgroundColor: "#E3256B", padding: "10px", position: "fixed", top: "175px", right: "20px", color: "white" }}
                        onClick={preference_click}>
                        Filter Your Preferences
                    </div>

                    <CSSTransition
                        in={isAnimated}
                        timeout={1200} // Duration of the transition in milliseconds
                        classNames="fade"
                        unmountOnExit>

                        <Grid container xs={12} >

                        <Grid item xs={12}><Typography  variant="h6" align="left" width="500px" style={{padding:"10px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"10px" , marginBottom:"40px"}}>{"Edit Partner Preference Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>


                            <Grid item xs={12} md={6} spacing={1}>


                                <Grid container xs={12} md={12} spacing={2}>
                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={ageRange} label={t('from_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('age_from')}
                                            value={partnerPrefField.age_from}
                                            onMouseDown={handleClick('age_from')}
                                        />
                                        {errors.age_from && <span style={{ color: 'red' }}>required!</span>}

                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={ageRange} label={t('to_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('age_to')}
                                            value={partnerPrefField.age_to}
                                            onMouseDown={handleClick('age_to')}
                                        />
                                        {errors.age_to && <span style={{ color: 'red' }}>required!</span>}

                                    </Grid>

                                </Grid>


                                <Grid container xs={12} md={12} spacing={2}>
                                    <Grid item xs={12} md={5.5}  >


                                        <DropdownSelect options={height} label={t('from_height')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('height_from')}
                                            value={partnerPrefField.height_from}
                                            onMouseDown={handleClick('height_from')} />

                                        {errors.height_from && <span style={{ color: 'red' }}>required!</span>}


                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={height} label={t('to_height')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('height_to')}
                                            value={partnerPrefField.height_to}
                                            onMouseDown={handleClick('height_to')} />

                                        {errors.height_to && <span style={{ color: 'red' }}>required!</span>}


                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "10px" }}>

                                    <MultiselectDropdown options={maritalStatus} label={t('marital_status_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"60%"} borderWidth={0.5}
                                        onChange={handleChange('marital_status')}
                                        onMouseDown={handleClick('marital_status')}

                                        initialSelectedValues={maritalStatus.filter(vals => partnerPrefField.marital_status.includes(vals.value))} />

                                    {errors.marital_status && <span style={{ color: 'red' }}>required!</span>}

                                </Grid>

                                <Grid container xs={12} md={12} spacing={2} style={{ marginTop: "-5px" }}>

                                    <Grid item xs={12} md={5.5} >

                                        <DropdownSelect options={caste} label={t('caste_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('caste')}
                                            value={partnerPrefField.caste} />

                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={subcaste} label={t('subcaste_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('subcaste')}
                                            value={partnerPrefField.subcaste} />

                                    </Grid>

                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "10px" }}>

                                    <MultiselectDropdown options={skintone} label={t('skintone_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('skintone')}
                                        initialSelectedValues={skintone.filter(vals => partnerPrefField.skintone.includes(vals.value))} />

                                </Grid>


                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={stateList} label={t('state_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('state')}
                                        initialSelectedValues={stateList.filter(vals => partnerPrefField.state.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={cityList} label={t('city_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('city')}
                                        initialSelectedValues={cityList.filter(vals => partnerPrefField.city.includes(vals.value))} />

                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={degreeList} label={t('education_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"50%"} borderWidth={0.5}
                                        onChange={handleChange('education')}
                                        initialSelectedValues={degreeList.filter(vals => partnerPrefField.education.includes(vals.value))} />

                                </Grid>




                            </Grid>

                            <Grid container xs={12} md={0.5} >
                                <div style={{ backgroundColor: "#E3256B", width: "3px" }}></div>
                            </Grid>

                            <Grid container xs={12} md={5.5} spacing={2} style={{ marginTop: "10px" }}>



                                <Grid item xs={12} md={10.7} style={{ marginTop: "-15px" }}>

                                    <MultiselectDropdown options={occupationList} label={t('occuaption_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"50%"} borderWidth={0.5}
                                        onChange={handleChange('occupation')}
                                        initialSelectedValues={occupationList.filter(vals => partnerPrefField.occupation.includes(vals.value))} />

                                </Grid>

                                <Grid item xs={12} md={10.7}  >

                                    <MultiselectDropdown options={diet} label={t('diet_type_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('diet_type')}
                                        initialSelectedValues={diet.filter(vals => partnerPrefField.diet_type.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} >

                                    <MultiselectDropdown options={family_value} label={t('family_value_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('family_value')}
                                        initialSelectedValues={family_value.filter(vals => partnerPrefField.family_value.includes(vals.value))} />

                                </Grid>





                                <Grid item xs={12} md={10.7} style={{ marginTop: "-15px" }}>

                                    <DropdownSelect options={annual_income} label={t('annual_income_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('annual_income')}
                                        value={partnerPrefField.annual_income} />

                                </Grid>
                                <Grid item xs={12} md={10.7} style={{ marginTop: "-10px" }}>

                                    <MultiselectDropdown options={body_type} label={t('bodytype_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('body_type')}
                                        initialSelectedValues={body_type.filter(vals => partnerPrefField.body_type.includes(vals.value))} />

                                </Grid>



                                <Grid item xs={12} md={10.7}>

                                    <MultiselectDropdown options={smoke_type} label={t('smoke_type_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('smoke_type')}
                                        initialSelectedValues={smoke_type.filter(vals => partnerPrefField.smoke_type.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} >

                                    <MultiselectDropdown options={drink_type} label={t('drink_type_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('drink_type')}
                                        initialSelectedValues={drink_type.filter(vals => partnerPrefField.drink_type.includes(vals.value))}
                                    />

                                </Grid>

                                <Grid item xs={12} md={12} >


                                    <StyledTextField label={t('details_family_l')} focusedcolor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                        rows={3}
                                        onChange={handleChange('detail')}
                                        value={partnerPrefField.detail}
                                    />

                                </Grid>


                                <Prefernce_filter open={open} handleClosePass={handleClose} />

                            </Grid>

                        </Grid>
                    </CSSTransition>

                    <Button style={{
                        justifyContent: "center", alignContent: "center", marginTop: "30px", marginLeft: "50px",
                        background: "#E3256B", width: "400px", color: "white", fontWeight: "bold", marginBottom: "30px"
                    }} onClick={handleSubmit}>Submit your Partner Preferences</Button>

                </div>
            }


        </>);



}


export default PartnerPrefsAdmin;