import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import "../../../../styles/animationfade.css";
import { CSSTransition } from "react-transition-group";
import { produce } from "immer";
import { useState } from "react";
import { Button, Checkbox, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { DropdownSelect, StyledTextField } from "../../../../app_utils/input_fields";
import { Maincolor } from "../../../../common_utils/colors";
import { PROFILE_ID, USER_ID } from "../../../../common_utils/constants";
import RotatingHeart from "../../../../common_utils/loading_animator";
import { useLocation, useNavigate } from "react-router-dom";
import { useFamilyAdminContext } from "../../../contexts_admin/members_context/familyContextAdmin";
import React from "react";








function FamilyInputAdmin() {

    const { data_family_admin, loading, error, family_insert_admin , family_update_admin , submit_familydetailsAdmin, update_familydetailsAdmin ,fetchFamilyItemsAdmin } = useFamilyAdminContext();

    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [values, setFmlValues] = useState([]);
    const [type, setFmlType] = useState([]);
    const [status, setStatus] = useState([]);
    const [houseType, sethouseType] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [load, loadset] = useState(true);

    const customColorStyle = {
        color: "#7f7f7f", // Replace with your custom color code
        '&$checked': {
            color: Maincolor, // Replace with your custom color code for checked state
        },
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };



    const location = useLocation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');

       
        fetchFamilyItemsAdmin({
                "userId":userId,
                "communityId":communityId,
                "original": "en",
                "translate": ["en"]
            });
        
      }, [location]);



    useEffect(() => {
        setAnimated(true)

        if (data_family_admin.length > 0) {

            const fml_values = Object.values(data_family_admin[1][0]).map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));

            const fml_type = Object.values(data_family_admin[2][0]).map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));


            const fml_status = Object.values(data_family_admin[3][0]).map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));


            const house_type = Object.values(data_family_admin[4][0]).map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));

            setFmlValues(fml_values);
            setFmlType(fml_type);
            setStatus(fml_status)
            sethouseType(house_type);

            const setup = data_family_admin[0].flat()[0]["0"];

            

           if(setup != undefined){

        const nextState = produce((draft) => {
     
        draft["family_value"] =   setup.family_value;
        draft["family_type"] =  setup.family_type;
        draft["family_status"] =   setup.family_status;
        draft["no_brothers"] =   setup.no_brother;
        draft["no_sisters"] =   setup.no_sister;
        draft["married_brothers"] =   setup.married_brother;
        draft["married_sisters"] =   setup.married_sister;
        draft["father_name"] =  setup.father_name;
        draft["mother_name"] =   setup.mother_name;
        draft["father_occupation"] =   setup.father_occupation;
        draft["mother_occupation"] =   setup.mother_occupation;
        draft["house_owned"] =   setup.house_owned;
        draft["house_type"] =   setup.house_type;
        draft["details_family"] =   setup.detail_family;
    
    });
       
      
    // Set the updated state
    setFamilyFields(nextState);
}


}

    }, [data_family_admin]);


    const navigate = useNavigate();

   useEffect(() => {

   

    if(family_insert_admin.affectedRows >= 1 ||  family_update_admin.success == 1){

     setTimeout(() => {
      
        navigate(-1);
     }, 1500);

    }


   }, [family_insert_admin , family_update_admin]);




    const [familyFields, setFamilyFields] = useState({
        family_value: '',
        family_type: '',
        family_status: '',
        no_brothers: '',
        no_sisters: '',
        married_brothers: '',
        married_sisters: '',
        father_name: '',
        mother_name: '',
        father_occupation: '',
        mother_occupation: '',
        house_owned: '',
        house_type: '',
        parent_stay_opeartion: '',
        details_family: ''
    });


    const [errors, setErrors] = useState({
        family_value: false,
        family_type: false,
        family_status: false,
        no_brothers: false,
        no_sisters: false,
        married_brothers: false,
        married_sisters: false,
        father_name: false,
        mother_name: false,
        father_occupation: false,
        mother_occupation: false,
        house_owned: false,
        house_type: false,
        parent_stay_opeartion: false,
        details_family: false
    });




    const [hasBeenFocused, setHasBeenFocused] = useState({
        family_value: false,
        family_type: false,
        family_status: false,
        no_brothers: false,
        no_sisters: false,
        married_brothers: false,
        married_sisters: false,
        father_name: false,
        mother_name: false,
        father_occupation: false,
        mother_occupation: false,
        house_owned: false,
        house_type: false,
        parent_stay_opeartion: false,
        details_family: false
    });




    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                if (familyFields[fieldName] !== '') {
                    draftErrors[fieldName] = false;
                } else {
                    draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                }


            })
        );

    }


    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setFamilyFields(nextState);

        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {
                const trimmedValue = newValue;

                //alert(trimmedValue);
                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';


            })
        );


    };


    const handleSubmit = () => {


        const newErrors = produce(errors, (draftErrors) => {
            for (const field in familyFields) {
                const trimmedValue = familyFields[field];

                draftErrors[field] = trimmedValue === '' ;
                draftErrors["father_occupation"] = false;
                draftErrors["mother_occupation"] = false;
                draftErrors["parent_stay_opeartion"] = false;
                draftErrors["details_family"] = false;

            }
        });


        setErrors(newErrors);

      //  if (Object.values(newErrors).some(error => error) == true) {

            //alert(newErrors);

      //  } else {


            const searchParams = new URLSearchParams(location.search);
            const userId = searchParams.get('userId');
            const communityId = searchParams.get('communityId');
            const profile_id = searchParams.get('profile_id');

           // alert(familyFields.married_brothers);

            loadset(false);
            const article = {
                "family_value": familyFields.family_value,
                "family_type":  familyFields.family_type,
                "family_status": familyFields.family_status,
                "no_brother":  familyFields.no_brothers,
                "no_sister":  familyFields.no_sisters,
                "married_brother":  familyFields.married_brothers,
                "married_sister":  familyFields.married_sisters,
                "father_name":  familyFields.father_name,
                "mother_name": familyFields.mother_name,
                "father_occupation": familyFields.father_occupation,
                "mother_occupation": familyFields.mother_occupation,
                "house_owned": familyFields.house_owned,
                "house_type": familyFields.house_type,
                "parents_stay_options": familyFields.parent_stay_opeartion,
                "detail_family": familyFields.details_family,
                "userId": userId,
                "communityId": communityId,
                "profileId": profile_id,
                "Id": data_family_admin[0].flat()[0]["0"] == undefined  ? "0" : data_family_admin[0].flat()[0]["0"].Id
            }



            if (data_family_admin[0].flat()[0]["0"] == undefined) {
                submit_familydetailsAdmin(article);
            } else {
                update_familydetailsAdmin(article);
            }


            setTimeout(() => {

                alert(family_update_admin.success);
                
            }, 3000);



      //  }







    };




    return (<>

{  load == false ?  <RotatingHeart/> : 
        <div style={{ display: 'grid', placeItems: 'center', position: "absolute", top: "50px", width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "40px" }}>
            
            <CSSTransition
                in={true}
                timeout={500} // Duration of the transition in milliseconds
                classNames="fade"
                unmountOnExit>

                <Grid container xs={12} >

                <Grid item xs={12}><Typography  variant="h6" align="center" width="600px" style={{padding:"5px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"10px" , marginBottom:"60px"}}>{"Edit Family Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>


                    <Grid container xs={12} md={7} columnSpacing={1} rowSpacing={1}>



                        <Grid item xs={12} md={5}  >

                            <DropdownSelect options={values} label={t('family_value_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('family_value')}
                                onMouseDown={handleClick('family_value')}
                                value={familyFields.family_value} />
                            {errors.family_value && <span style={{ color: 'red' }}>Please Select Family Value</span>}

                        </Grid>



                        <Grid item xs={12} md={5} >

                            <DropdownSelect options={type} label={t('family_type_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('family_type')}
                                onMouseDown={handleClick('family_type')}
                                value={familyFields.family_type} />

                            {errors.family_type && <span style={{ color: 'red' }}>Please Select Family Type</span>}

                        </Grid>


                        <Grid item xs={12} md={5}>


                            <StyledTextField label={t('no_brother_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('no_brothers')}
                                value={familyFields.no_brothers}
                            />
                            {errors.no_brothers && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>


                        <Grid item xs={12} md={5}>


                            <StyledTextField label={t('no_sisters_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('no_sisters')}
                                value={familyFields.no_sisters}
                            />
                            {errors.no_sisters && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>




                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('no_brothers_married_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('married_brothers')}
                                value={familyFields.married_brothers}
                            />
                            {errors.married_brothers && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>


                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('no_sisters_married_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('married_sisters')}
                                value={familyFields.married_sisters}
                            />
                            {errors.married_sisters && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>





                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('father_name_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('father_name')}
                                value={familyFields.father_name}
                            />
                            {errors.father_name && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>


                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('mother_name_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('mother_name')}
                                value={familyFields.mother_name}
                            />
                            {errors.mother_name && <span style={{ color: 'red' }}>Field is required</span>}

                        </Grid>







                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('father_occupation_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('father_occupation')}
                                value={familyFields.father_occupation}
                            />

                        </Grid>


                        <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>


                            <StyledTextField label={t('mother_occupation_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('mother_occupation')}
                                value={familyFields.mother_occupation}
                            />

                        </Grid>

                        <Grid container md={4.5} columnSpacing={2} rowSpacing={1} >

                            <Grid item xs={12} md={12} style={{ marginTop: "15px", marginLeft: "5px" }}>

                                <FormLabel style={{ fontWeight: "bold" }}>{t('house_owned_l')}</FormLabel>

                            </Grid>

                            <Grid item xs={12} md={7} style={{ marginTop: "-10px", marginLeft: "5px" }}>
                                <RadioGroup row value={familyFields.house_owned} onChange={handleChange('house_owned')}>
                                    <Grid container md={12} spacing={1}>
                                        <Grid item md={9}>
                                            <FormControlLabel value={"Owned"} control={<Radio />} label="Owned" />
                                        </Grid>
                                        <Grid item md={3}>
                                            <FormControlLabel value={"Rented"} control={<Radio />} label="Rented" />
                                        </Grid>

                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            {errors.house_owned && <span style={{ color: 'red' }}>Field is required</span>}
                        </Grid>


                    </Grid>

                    <Grid container xs={12} md={0.5} >
                        <div style={{ height: "95%", backgroundColor: Maincolor, width: "3px", marginLeft: "-40px" }}></div>
                    </Grid>

                    <Grid item xs={12} md={3.5} columnSpacing={1} rowSpacing={1} style={{ marginLeft: "-10px" }}>


                        <Grid item xs={12} md={12} >

                            <DropdownSelect options={status} label={t('family_status_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"120%"} borderWidth={0.5}
                                onChange={handleChange('family_status')}
                                onMouseDown={handleClick('family_status')}
                                value={familyFields.family_status} />
                            {errors.family_status && <span style={{ color: 'red' }}>Please Select Highest Education!</span>}

                        </Grid>


                        <Grid item xs={12} md={12} >

                            <DropdownSelect options={houseType} label={t('house_type_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"120%"} borderWidth={0.5}
                                onChange={handleChange('house_type')}
                                onMouseDown={handleClick('house_type')}
                                value={familyFields.house_type} />
                            {errors.house_type && <span style={{ color: 'red' }}>Please Select Highest Education!</span>}

                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: "15px" }}>


                            <StyledTextField label={t('details_family_l')} focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"120%"} borderWidth={0.5}
                                rows={5}
                                onChange={handleChange('details_family')}
                                value={familyFields.details_family}
                            />

                        </Grid>


                    </Grid>
                </Grid>
            </CSSTransition>


            <Button style={{
                justifyContent: "center", alignContent: "center", marginTop: "10px", marginLeft: "50px",
                background: "#E3256B", width: "400px", color: "white", fontWeight: "bold"
            }} onClick={handleSubmit}>Submit your Family Details</Button>
        </div>

}

    </>);



}

export default FamilyInputAdmin;