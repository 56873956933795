import { CSSTransition } from "react-transition-group";
import { useLifestyleContext } from "../../contexts/lifestylephysicalContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useEffect } from "react";
import { DropdownSelect, StyledTextField } from "../../app_utils/input_fields";
import { height } from "../../data/data";
import { generateWeight } from "../../common_utils/utils";
import { produce } from "immer";
import { Maincolor } from "../../common_utils/colors";
import { COMMUNITY_ID, PROFILE_ID, USER_ID } from "../../common_utils/constants";
import { useNavigate } from "react-router-dom";
import RotatingHeart from "../../common_utils/loading_animator";
import React from "react";






function LifestyleDetails({passPath}) {

    const { data, loading, error, submit_lifestyledetails, update_lifestyledetails, life_insert, life_update } = useLifestyleContext();
    const [isAnimated, setAnimated] = useState(false);
    const [weight, setWeight] = useState(0);

    const { t } = useTranslation();



    const [skintone, setSkintone] = useState([]);
    const [blood_group, setBloodGroup] = useState([]);
    const [body_type, setBodyType] = useState([]);
    const [diet_type, setDietType] = useState([]);
    const [is_handicap, setHandicap] = useState("");
    const [drink_type, setDrinkType] = useState([]);
    const [smoke_type, setsmokeType] = useState([]);
    const [load, loadset] = useState(true);


    useEffect(() => {


        passPath(t('lifestyle_label'));
    
        setAnimated(true);
        setWeight(generateWeight(45, 130));

        if (data.length > 0) {

            const skintone = data[2].flat();
            const blood_group = data[3].flat();

            const body_type = data[4].flat();
            const diet_type = data[5].flat();

            const drink_type = data[6].flat();
            const smoke_type = data[7].flat();

            setSkintone(skintone);
            setBloodGroup(blood_group);
            setBodyType(body_type);
            setDietType(diet_type);
            setDrinkType(drink_type);
            setsmokeType(smoke_type);


            const setup = data[0].flat()[0];

            if (setup != undefined) {

                const nextState = produce((draft) => {

                    draft["height"] = setup.height;
                    draft["weight"] = setup.weight;
                    draft["skintone"] = setup.skintone;
                    draft["blood_group"] = setup.blood_group;
                    draft["fitness"] = setup.fitness;
                    draft["body_type"] = setup.body_type;
                    draft["is_handicap"] = setup.is_handicap;
                    draft["handicap_details"] = setup.handicap_detail;
                    draft["diet_type"] = setup.diet_type;
                    draft["drink_type"] = setup.drink_type;
                    draft["smoke_type"] = setup.smoke_type;
                    draft["extra_detail_physic"] = setup.extra_detail_physic;

                });

                // Set the updated state
                setLifeFields(nextState);

            }


        }

    }, [data]);




    const navigate = useNavigate();

    useEffect(() => {

        if (life_insert.affectedRows >= 1 || life_update.success == 1) {
            setTimeout(() => {

                navigate('/contact', { replace: true });
            }, 2000);
        }

    }, [life_insert, life_update]);


    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;

        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                if (lifeFields[fieldName] !== '') {
                    draftErrors[fieldName] = false;
                } else {
                    draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                }


            })
        );

    }

    const [lifeFields, setLifeFields] = useState({
        height: '',
        weight: '',
        skintone: '',
        blood_group: '',
        fitness: '',
        body_type: '',
        is_handicap: '',
        handicap_details: '',
        diet_type: '',
        drink_type: '',
        smoke_type: '',
        extra_detail_physic: ''
    });


    const [errors, setErrors] = useState({
        height: false,
        weight: false,
        skintone: false,
        blood_group: false,
        fitness: false,
        body_type: false,
        is_handicap: false,
        handicap_details: false,
        diet_type: false,
        drink_type: false,
        smoke_type: false,
        extra_detail_physic: false
    });


    const [hasBeenFocused, setHasBeenFocused] = useState({
        height: false,
        weight: false,
        skintone: false,
        blood_group: false,
        fitness: false,
        body_type: false,
        is_handicap: false,
        handicap_details: false,
        diet_type: false,
        drink_type: false,
        smoke_type: false,
        extra_detail_physic: false
    });



    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setLifeFields(nextState);
    };

    const handleSubmit = () => {



        const newErrors = produce(errors, (draftErrors) => {
            for (const field in lifeFields) {
                const trimmedValue = lifeFields[field];

                draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
                draftErrors["handicap_details"] = false;
                draftErrors["extra_detail_physic"] = false;


            }
        });

        setErrors(newErrors);


       // alert("update");

        if (Object.values(newErrors).some(error => error) == true) {


            //alert(Object.values(newErrors));

        } else {


            loadset(false);
            const article =
            {
                "weight": lifeFields.weight,
                "height": lifeFields.height,
                "skintone": lifeFields.skintone,
                "blood_group": lifeFields.blood_group,
                "fitness": lifeFields.fitness,
                "body_type": lifeFields.body_type,
                "is_handicap": lifeFields.is_handicap,
                "handicap_detail": lifeFields.handicap_details,
                "diet_type": lifeFields.diet_type,
                "drink_type": lifeFields.drink_type,
                "smoke_type": lifeFields.smoke_type,
                "extra_detail_physic": lifeFields.extra_detail_physic,
                "userId": localStorage.getItem(USER_ID),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "profileId": localStorage.getItem(PROFILE_ID),
                "Id": data[0].flat()[0] == undefined ? "0" : data[0].flat()[0].Id
            };



            if (data[0].flat()[0] == undefined) {
                submit_lifestyledetails(article);
            } else {
                update_lifestyledetails(article);
            }



        }
    }



    return (
        <>
            {load == false ? <RotatingHeart /> :
                <div style={{ display: 'grid', placeItems: 'center', width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "230px" ,marginBottom:"60px" }}>
                    <CSSTransition
                        in={isAnimated}
                        timeout={1000} // Duration of the transition in milliseconds
                        classNames="fade"
                        unmountOnExit>

                        <Grid container xs={12} md={12}>

                            <Grid container xs={12} md={6} columnSpacing={2} rowSpacing={1}>



                                <Grid item xs={12} md={6} >

                                    <DropdownSelect options={height} label={t('height_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('height')}
                                        onMouseDown={handleClick('height')}
                                        width={"100%"}
                                        value={lifeFields.height} />

                                    {errors.height && <span style={{ color: 'red' }}>required!</span>}

                                </Grid>

                                <Grid item xs={12} md={6} >

                                    <DropdownSelect options={weight} label={t('weight_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('weight')}
                                        onMouseDown={handleClick('weight')}
                                        width={"100%"}
                                        value={lifeFields.weight} />

                                    {errors.weight && <span style={{ color: 'red' }}>required!</span>}


                                </Grid>


                                <Grid item xs={12} md={6} style={{ marginTop: "-20px" }}>

                                    <DropdownSelect options={skintone} label={t('skintone_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('skintone')}
                                        onMouseDown={handleClick('skintone')}
                                        width={"100%"}
                                        value={lifeFields.skintone} />

                                    {errors.skintone && <span style={{ color: 'red' }}>required!</span>}


                                </Grid>

                                <Grid item xs={12} md={6} style={{ marginTop: "-20px" }}>

                                    <DropdownSelect options={blood_group} label={t('blood_group_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('blood_group')}
                                        onMouseDown={handleClick('blood_group')}
                                        width={"100%"}
                                        value={lifeFields.blood_group} />
                                    {errors.blood_group && <span style={{ color: 'red' }}>required!</span>}


                                </Grid>




                                <Grid item xs={12} md={6} style={{ marginTop: "-20px" }}>

                                    <DropdownSelect options={body_type} label={t('bodytype_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('body_type')}
                                        onMouseDown={handleClick('body_type')}
                                        width={"100%"}
                                        value={lifeFields.body_type} />
                                    {errors.body_type && <span style={{ color: 'red' }}>required!</span>}


                                </Grid>

                                <Grid item xs={12} md={6} style={{ marginTop: "-20px" }}>

                                    <DropdownSelect options={diet_type} label={t('diet_type_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('diet_type')}
                                        onMouseDown={handleClick('diet_type')}
                                        width={"100%"}
                                        value={lifeFields.diet_type} />

                                    {errors.diet_type && <span style={{ color: 'red' }}>required!</span>}


                                </Grid>


                                <Grid item xs={12} md={12} style={{ marginTop: "-5px" }}>

                                    <FormLabel >{t('is_handicap_l')}</FormLabel>

                                </Grid>

                                <Grid item xs={12} md={12} style={{ marginTop: "-5px" }}>
                                    <RadioGroup row value={lifeFields.is_handicap} onChange={handleChange('is_handicap')}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>


                                    {errors.is_handicap && <span style={{ color: 'red' }}>required!</span>}
                                </Grid>


                                <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>

                                    <StyledTextField label={t('handicap_detail_l')} focusedcolor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                        onChange={handleChange('handicap_details')}
                                        value={lifeFields.handicap_details}
                                        rows={3}
                                        disabled={lifeFields.is_handicap == "Yes" ? false : true }
                                    />

                                </Grid>

                            </Grid>

                            <Grid container xs={12} md={0.5} style={{ marginLeft: "50px" }}>
                                <div style={{ height: "100%", backgroundColor: Maincolor, width: "3px" }}></div>
                            </Grid>



                            <Grid container md={4.5} columnSpacing={2} rowSpacing={1}>

                                <Grid item xs={12} md={12} style={{ marginTop: "15px" }}>

                                    <FormLabel style={{ fontWeight: "bold" }}>{t('fitness_l')}</FormLabel>

                                </Grid>

                                <Grid item xs={12} md={7} style={{ marginTop: "-40px" }}>
                                    <RadioGroup row value={lifeFields.fitness} onChange={handleChange('fitness')}>
                                        <Grid container spacing={1}>
                                            <Grid item md={4}>
                                                <FormControlLabel value={"High"} control={<Radio />} label="High" />
                                            </Grid>
                                            <Grid item md={4}>
                                                <FormControlLabel value={"Ok"} control={<Radio />} label="Ok" />
                                            </Grid>
                                            <Grid item md={4}>
                                                <FormControlLabel value={"Low"} control={<Radio />} label="Low" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>

                                    {errors.fitness && <span style={{ color: 'red' }}>required!</span>}
                                </Grid>



                                <Grid item xs={12} md={12} style={{ marginTop: "-40px" }}>

                                    <DropdownSelect options={drink_type} label={t('drink_type_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('drink_type')}
                                        width={"100%"}
                                        onMouseDown={handleClick('drink_type')}
                                        value={lifeFields.drink_type} />

                                    {errors.drink_type && <span style={{ color: 'red' }}>required!</span>}

                                </Grid>

                                <Grid item xs={12} md={12} style={{ marginTop: "-50px" }}>

                                    <DropdownSelect options={smoke_type} label={t('smoke_type_l')} focusedBorderColor={"#E3256B"}
                                        borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                                        sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                                        onChange={handleChange('smoke_type')}
                                        width={"100%"}
                                        onMouseDown={handleClick('smoke_type')}
                                        value={lifeFields.smoke_type} />
                                    {errors.smoke_type && <span style={{ color: 'red' }}>required!</span>}

                                </Grid>


                                <Grid item xs={12} md={12} style={{ marginTop: "-30px" }}>

                                    <StyledTextField label={t('extra_physic_details')} focusedcolor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                        onChange={handleChange('extra_detail_physic')}
                                        value={lifeFields.extra_detail_physic}
                                        rows={3}
                                    />
                                </Grid>




                            </Grid>

                        </Grid>

                    </CSSTransition>

                    <Button style={{
                        justifyContent: "center", alignContent: "center", marginTop: "30px",
                        background: "#E3256B", width: "400px", color: "white", fontWeight: "bol"
                    }} onClick={handleSubmit} >Submit Lifestyle Details</Button>

                </div>

            }

        </>
    );




};

export default LifestyleDetails;