



import { doubleMetaphone } from 'double-metaphone';

const matchPronunciationUsingSome = (word1, word2) => {

  if(word1 != undefined){
  const [primary1, secondary1] = doubleMetaphone(word1);
  const [primary2, secondary2] = doubleMetaphone(word2);

  const encodings1 = [primary1, secondary1];
  const encodings2 = [primary2, secondary2];

  return encodings1.some(encoding => encodings2.includes(encoding));
  }else{

    return false;
  }
};

  export default matchPronunciationUsingSome;