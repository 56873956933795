import React, { useEffect } from 'react';
import '../../styles_admin/ArrayComponent.css'; // Import the CSS file
import {Modal , Box, Button} from "@mui/material";
import { useState } from 'react';
import { useAdminMembersContext } from '../../contexts_admin/member_context';

function ListValues({combined , open2 , handleClose , categories , executeInsert}) {
  // Example: Large array containing arrays of arrays


  const { master_table_fetch_all , list_master_data , INSERT_MULTI_BASICDETAIL , INSERT_MULTI_EDUDETAIL ,INSERT_MULTI_FAMILYDETAIL , INSERT_MULTI_HOROSCOPEDETAIL ,INSERT_MULTI_CONTACTDETAIL , INSERT_MULTI_LIFESTYLEDETAIL ,INSERT_MULTI_NOTIFICATION , INSERT_MULTI_NOTIFICATIONCOUNT ,INSERT_MULTI_OCCUPATIONDETAIL , INSERT_MULTI_PARTNER_PREFERENCE ,INSERT_MULTI_PHOTO ,INSERT_MULTI_PROOF ,INSERT_MULTI_SETTINGS , data_multibasic , data_multicontact ,data_multieducation, data_multifamily ,data_multilifestyle, data_multioccupation ,data_multihoroscope ,data_multiphoto ,data_multipartnerprefs, data_multinotification , data_multinotification_count, data_multisettings ,data_multiproof,
    INSERT_MULTI_ANNUALINCOME , INSERT_MULTI_BIRTHSTAR , INSERT_MULTI_BLOODGROUP ,INSERT_MULTI_BODYTYPE ,INSERT_MULTI_CASTE , INSERT_MULTI_CITY ,INSERT_MULTI_CREATEDBY ,INSERT_MULTI_BIGINSTITUTE ,INSERT_MULTI_DEGREE ,INSERT_MULTI_DIETTYPE , INSERT_MULTI_DRINKTYPE ,INSERT_MULTI_FMLSTATUS ,INSERT_MULTI_FMLTYPE ,INSERT_MULTI_FMLVALUES ,INSERT_MULTI_HOUSETYPE ,INSERT_MULTI_MARITALSTATUS ,INSERT_MULTI_LANGUAGES ,INSERT_MULTI_OCCUPATION ,INSERT_MULTI_RASHI ,INSERT_MULTI_SKINTONE ,INSERT_MULTI_SMOKETYPE ,INSERT_MULTI_STATE ,INSERT_MULTI_SUBCASTE ,caste_fetch,
    data_annual_income , data_birthstar,data_blood_group, data_bodytype,data_caste,data_city,data_createdby,data_biginstitute,data_degree, data_diet_type,
    data_drinktype,data_fmlstatus,data_fmltype,data_fmlvalues,data_housetype,data_maritalstatus, data_languages, data_occupation,data_rashi,
    data_skintone,data_smoketype, data_state,  data_subcaste, list_caste_admin , EXECUTE_CREATE_STATEMENTS ,INSERT_MULTI_REGISTERATION
,   data_registeration , master_table_fetch_all_clear} = useAdminMembersContext();

   useEffect(()=>{

    setopen(open2);

   }, []);


  const [query, setValue] = useState('100');
  const [isopen , setopen] = useState(false);
  const [batchdata, setData] = useState([
    {
      sheetName: 'Sheet1',
      rows: [
        []
      ],
      rowCount: 0
    }
    ]);


  const addNewSheetWithData = (sheetName , newRows) => {

    setData(prevData => [
      ...prevData,
      {
        sheetName,
        rows: newRows,
        rowCount: newRows.length
      }
    ]);

    
  };

  
  const handleClick = async () => {
    
    const objectInCombined = combined[0];

    // Loop through the properties of the object
     Object.keys(objectInCombined).forEach(key => {

    if(key == "caste"){
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"caste":array[index]["caste"] , "religion_id":"1" , "communityId": localStorage.getItem("communityIdSet") });
        });
        addNewSheetWithData("caste" , newRows);

    } else if(key == "marital_status"){

        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["marital_status"] });
        });


        addNewSheetWithData("marital_status" , newRows);
    
      } else if(key ==  "subcaste"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"subcaste":array[index]["subcaste"] , "casteId":array[index]["caste"]  , "communityId": localStorage.getItem("communityIdSet") });

        });
        addNewSheetWithData("subcaste" , newRows);

      }else if(key ==  "created_by"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"value":array[index]["Id"] , "label":array[index]["created_by"] });
        });
        addNewSheetWithData("created_by" , newRows);

      }else if(key == "language"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"value":array[index]["Id"] , "label":array[index]["language"] });
        });
        addNewSheetWithData("language" , newRows);
        

      }else if(key == "skintone"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"value":array[index]["Id"] , "label":array[index]["skintone"] });
        });
        addNewSheetWithData("skintone" , newRows);
        
      
      }else if(key == "blood_group"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {

            newRows.push({"value":array[index]["Id"] , "label":array[index]["skintone"] });
        });
        addNewSheetWithData("skintone" , newRows);
       

      }else if(key  == "perm_state"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"state_name": array[index]["state_rows"] , "state_hindi":"" , "state_guj":"" , "state_marathi":"" , "state_tamil":"" , "state_urdu":"" , "country_id":"1"});
        });
        addNewSheetWithData("perm_state" , newRows);

      }else if(key  == "work_state"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"state_name": array[index]["state_rows_w"] , "state_hindi":"" , "state_guj":"" , "state_marathi":"" , "state_tamil":"" , "state_urdu":"" , "country_id":"1"});
        });
        addNewSheetWithData("work_state" , newRows);

      }else if(key  == "perm_city"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"city_name":array[index]["city_rows"] , "city_hindi":"" , "city_guj":"" , "city_marathi":"" , "city_tamil":"" , "city_urdu":"" ,  "state_id":array[index]["stateId"] , "country_id":"1"});
        });
        addNewSheetWithData("perm_city" , newRows);

      }else if(key  == "work_city"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"city_name":array[index]["city_rows_w"] , "city_hindi":"" , "city_guj":"" , "city_marathi":"" , "city_tamil":"" , "city_urdu":"" ,  "state_id":array[index]["stateId"] , "country_id":"1"});
        });
        addNewSheetWithData("work_city" , newRows);

      }else if(key  == "degree"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"degree_name":array[index]["degree"] , "degree_hindi":"" , "degree_guj":"" , "degree_marathi":"" , "degree_tamil":"" , "degree_urdu":""});
        });

        addNewSheetWithData("degree" , newRows);

      }else if(key  == "institute"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"institute_name": array[index]["iitiimnit"] , "institute_hindi":"" , "institute_guj":"" , "institute_marathi":"" , "institute_tamil":"" , "institute_urdu":""});
        });
        addNewSheetWithData("institute" , newRows);

      }else if(key  == "family_type"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["fml_type"] });
        });
        addNewSheetWithData("family_type" , newRows);

      }else if(key == "family_status"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["fml_status"] });
        });
        addNewSheetWithData("family_status" , newRows);
       

      }else if(key == "family_value"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["fml_value"] });
        });
        addNewSheetWithData("family_value" , newRows);
       

      }else if(key == "house_type"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["house_type"] });
        });
        addNewSheetWithData("house_type" , newRows);
       

      }else if(key == "skintone"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["skintone"] });
        });
        addNewSheetWithData("skintone" , newRows);
       

      }else if(key == "rashi"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["rashi_value"] });
        });
        addNewSheetWithData("rashi" , newRows);
       
      }else if(key == "birth_star"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
            newRows.push({"value":array[index]["Id"] , "label":array[index]["birth_star"] });
        });

        addNewSheetWithData("birth_star" , newRows);
       
      }else if(key == "occupation"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
          newRows.push({"occupation":array[index]["occupation"] , "occup_hindi":"" , "occup_guj":"" , "occup_marathi":"", "occup_tamil":"" , "occup_urdu":""});
        });

        addNewSheetWithData("occupation" , newRows);
       
      }else if(key == "diet_type"){
        
        const newRows = [];
        objectInCombined[key].forEach(function(element, index, array) {
          newRows.push({"value":array[index]["Id"] , "label":array[index]["diet_type"] });
      });

        addNewSheetWithData("diet_type" , newRows);
       
      }
    
 
});





 Promise.all([
    
    executeBatchForFunction(INSERT_MULTI_MARITALSTATUS , 1),
    executeBatchForFunction(INSERT_MULTI_CREATEDBY, 2),
    executeBatchForFunction(INSERT_MULTI_CASTE , 3),
  
    executeBatchForFunction(INSERT_MULTI_SUBCASTE, 4),
     
    executeBatchForFunction(INSERT_MULTI_LANGUAGES, 5),
    executeBatchForFunction(INSERT_MULTI_STATE, 6),
    executeBatchForFunction(INSERT_MULTI_CITY, 7),
    executeBatchForFunction(INSERT_MULTI_STATE, 8),
   
    executeBatchForFunction(INSERT_MULTI_CITY, 9),
    executeBatchForFunction(INSERT_MULTI_DEGREE, 10),
    executeBatchForFunction(INSERT_MULTI_BIGINSTITUTE, 11),
    executeBatchForFunction(INSERT_MULTI_FMLTYPE, 12),
    executeBatchForFunction(INSERT_MULTI_FMLSTATUS, 13),
    executeBatchForFunction(INSERT_MULTI_FMLVALUES, 14),
    executeBatchForFunction(INSERT_MULTI_HOUSETYPE, 15),
    executeBatchForFunction(INSERT_MULTI_SKINTONE, 16),
    executeBatchForFunction(INSERT_MULTI_RASHI, 17),
    executeBatchForFunction(INSERT_MULTI_BIRTHSTAR, 18),
    
    executeBatchForFunction(INSERT_MULTI_DIETTYPE, 19),
    executeBatchForFunction(INSERT_MULTI_OCCUPATION, 20),

   ]).then((results) => {


    setopen(false);
     executeInsert();
     
   }).catch((err) => {

    alert("problem executing");
   
   });

  };




  const executeBatchForFunction =  (insertFunction , index) => {



    setData((batchdata) => {

      console.log(batchdata);

      const totalDataSize = batchdata[index].rowCount;
      let startIndex = 0;
      let incre = 0;

     // alert(totalDataSize);
       //  alert(batchdata[index].rows[0]["Id"]);

       console.log(totalDataSize+" +=+ "+startIndex);

      while (startIndex < totalDataSize) {

        //alert(startIndex+"----"+totalDataSize);

         if( (totalDataSize-incre) ==  totalDataSize%query ){
          //const endIndex = Math.min(totalDataSize - incre , totalDataSize);
          const batch = batchdata[index].rows.slice(startIndex , totalDataSize);

         // alert("batch lenmgth : "+batch);

          insertFunction(batch);

          startIndex = totalDataSize;

          console.log(batch.length+"=");
          

         }else{


          const endIndex = Math.min(startIndex + query , totalDataSize);
          const batch = batchdata[index].rows.slice(startIndex , endIndex);

          insertFunction(batch);

          startIndex = endIndex;

          console.log(batch.length+"=");

          incre += 100;

          
         }

          
      }
      return batchdata;
    });

    

       

       
  

  };




  const getValues = (key) => {
    return combined[0][key]?.map(item => Object.values(item)[0]) || [];
  };

  return (
    <div className="grid-container">

<Modal
            open={isopen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">

            <Box sx={{ width: '100%', // Set the width of the Box
        height: '80%', // Set the height of the Box
        overflowY: 'auto', // Enable vertical scrolling
        border: '1px solid #ccc', // Optional: Add border for visibility
        padding: '16px', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>

      {Object.keys(combined[0]).map((key, index) => (

<div>
<div className="grid-header">{key.replace(/_/g, ' ').toUpperCase()}</div>
        <div key={index} className="grid-row">
         
          {getValues(key).map((value, idx) => (
            <div key={idx} className="grid-item">{value}</div>
          ))}
        </div>

        
        </div>

        
      ))}


<button onClick={handleClick}>Submit Master Data</button>

      </Box>
      </Modal>
    </div>
  );
}

export default ListValues;