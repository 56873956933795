import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DropdownSelect, StyledTextField } from "../../../../app_utils/input_fields";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { produce } from "immer";
import { useEffect } from "react";
import "../../../../styles/animationfade.css";
import { PROFILE_ID, TRANSLATED_LANG, USER_ID } from "../../../../common_utils/constants";
import { employmentType } from "../../../../common_utils/utils";
import { EmploymentType } from "../../../../data/data";
import { DateTime } from "luxon";
import { lookupViaCity } from "city-timezones";
import { useLocation, useNavigate } from "react-router-dom";
import RotatingHeart from "../../../../common_utils/loading_animator";
import { useOccupationAdminContext } from "../../../contexts_admin/members_context/occupationdetailsContextAdmin";
import React from "react";



function Occupational_DetailsAdmin() {

    const { data_occupation_admin , loading, error, occupation_insert_admin, occupation_update_admin , submit_occupationdetailsAdmin , update_occupationdetailsAdmin , fetchOccupationItemsAdmin } = useOccupationAdminContext();

    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();
    const [occupation_list, setOccupation] = useState([]);
    const [annualIncome, setAnualIncome] = useState([]);
    const [load, loadset] = useState(true);




    const langwiseOccupation = (occupation_data) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occup_name = occupation_data.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));


            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        }


    }





    const location = useLocation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');

       
        fetchOccupationItemsAdmin({
            "userId":userId,
            "communityId":communityId,
            "original": "en",
            "translate": ["en" , "en"]
        });
        
      }, [location]);




    useEffect(() => {

        /*  const cityLookup = lookupViaCity('Ahmedabad');
  
          const timezoneIdentifier = cityLookup[0].timezone;
          const now = DateTime.now().setZone(timezoneIdentifier);
          const offsetInHours = now.offset / 60;
    
          alert(offsetInHours);
  
          */

        if (data_occupation_admin.length > 0) {

            langwiseOccupation(data_occupation_admin[1].flat());

            const annualIncome = data_occupation_admin[2].flat().map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));

            setAnualIncome(annualIncome);


            const setup = data_occupation_admin[0].flat()[0];

            if (setup != undefined) {

                const nextState = produce((draft) => {

                    draft["occupation"] = setup.occupation_list;
                    draft["detail"] = setup.occupation_detail;
                    draft["income"] = setup.annual_income;
                    draft["employment_type"] = setup.employment_type;
                    draft["office_address"] = setup.office_address;
                

                });

                // Set the updated state
                setOccupationFields(nextState);
            }


        }


    }, [data_occupation_admin]);








    const navigate = useNavigate();

   useEffect(() => {

      if(occupation_insert_admin.affectedRows >= 1 ||  occupation_update_admin.success == 1){
     setTimeout(() => {
      
        navigate(-1);
     }, 1200);
    }


   }, [occupation_insert_admin , occupation_update_admin]);



    const [occupationFields, setOccupationFields] = useState({
        occupation: '',
        detail: '',
        income: '',
        employment_type: '',
        office_address: '',
    });

    const [errors, setErrors] = useState({
        occupation: false,
        detail: false,
        income: false,
        employment_type: false,
        office_address: false,
    });

    const [hasBeenFocused, setHasBeenFocused] = useState({
        occupation: false,
        detail: false,
        income: false,
        employment_type: false,
        office_address: false,
    });


    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setOccupationFields(nextState);
        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {
                const trimmedValue = newValue;

                //alert(trimmedValue);

                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';


            })
        );

    };




    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                if (occupationFields[fieldName] !== '') {
                    draftErrors[fieldName] = false;
                } else {
                    draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                }


            })
        );

    }




    const handleSubmit = () => {


        const newErrors = produce(errors, (draftErrors) => {
            for (const field in occupationFields) {
                const trimmedValue = occupationFields[field];

                draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
                draftErrors["detail"] = false;


            }
        });




    //    setErrors(newErrors);


    //    if (Object.values(newErrors).some(error => error) == true) {



    //    } else {


            const searchParams = new URLSearchParams(location.search);
            
            const userId = searchParams.get('userId');
            const communityId = searchParams.get('communityId');
            const profile_id = searchParams.get('profile_id');

            loadset(false);
            const article = {
                "occupation_list": occupationFields.occupation,
                "occupation_detail": occupationFields.detail,
                "annual_income": occupationFields.income,
                "employment_type": occupationFields.employment_type,
                "office_address": occupationFields.office_address,
                "userId": userId,
                "communityId": communityId,
                "profileId": profile_id,
                "Id": data_occupation_admin[0].flat()[0] == undefined ? "0" : data_occupation_admin[0].flat()[0].Id
            }



            if (data_occupation_admin[0].flat()[0] == undefined) {
                submit_occupationdetailsAdmin(article);
            } else {
                update_occupationdetailsAdmin(article);
            }




     //   }




    };




    return (

        <>

{  load == false ?  <RotatingHeart/> : 
            <div style={{ position: "absolute", top: "50px", width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "40px" }}>
                <CSSTransition
                    in={true}
                    timeout={500} // Duration of the transition in milliseconds
                    classNames="fade"
                    unmountOnExit>

                    <Grid container xs={12} >

                    <Grid item xs={12}><Typography  variant="h6" align="center" width="400px" style={{padding:"5px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"10px" , marginBottom:"40px"}}>{"Edit Occupational Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>


                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>


                            <Grid item xs={12} md={12} >

                                <DropdownSelect options={occupation_list} label={t('occupation_list_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('occupation')}
                                    onMouseDown={handleClick('occupation')}
                                    value={occupationFields.occupation} />

                                {errors.occupation && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12}>


                                <StyledTextField label={t('occupation_details_label')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    rows={3}
                                    onChange={handleChange('detail')}
                                    value={occupationFields.detail}
                                />


                            </Grid>

                            <Grid item xs={12} md={12} >

                                <DropdownSelect options={annualIncome} label={t('annual_income_p_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('income')}
                                    onMouseDown={handleClick('income')}
                                    value={occupationFields.income} />

                                {errors.income && <span style={{ color: 'red', marginBottom: "5px" }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>

                                <DropdownSelect options={EmploymentType} label={t('employment_type_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('employment_type')}
                                    onMouseDown={handleClick('employment_type')}
                                    value={occupationFields.employment_type} />

                                {errors.employment_type && <span style={{ color: 'red', marginBottom: "5px" }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>


                                <StyledTextField label={t('office_address_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    rows={3}
                                    onChange={handleChange('office_address')}
                                    value={occupationFields.office_address}
                                />

                                {errors.office_address && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>


                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>
                                <Button type="button" style={{
                                    justifyContent: "center", alignContent: "center",
                                    background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                                }} onClick={handleSubmit}>Submit Occupation Details</Button>

                            </Grid>


                        </Grid>

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>

                        </Grid>


                    </Grid>

                </CSSTransition>

            </div>

                            }



        </>

    );


}

export default Occupational_DetailsAdmin;