import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Maincolor, Secondcolor } from "../../../../common_utils/colors";
import { useTranslation } from "react-i18next";
import { DropdownSelect, StyledTextField } from "../../../../app_utils/input_fields";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { produce } from "immer";
import { useEffect } from "react";
import "../../../../styles/animationfade.css";
import { PROFILE_ID, TRANSLATED_LANG, USER_ID } from "../../../../common_utils/constants";
import RotatingHeart from "../../../../common_utils/loading_animator";
import { useLocation, useNavigate } from "react-router-dom";
import { useEducationAdminContext } from "../../../contexts_admin/members_context/educationalContextAdmin";
import React from "react";





function EducationalAdmin() {

    const { data_education_admin, loading, error , submit_educationaldetailsAdmin , update_educationaletailsAdmin , education_update_admin ,education_insert_admin , fetchEducationItemsAdmin} = useEducationAdminContext();

    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedIIT, setIsCheckedIIT] = useState(false);

    const [degree, setDegree] = useState([]);
    const [institute, setInstitute] = useState([]);
    const [load, loadset] = useState(true);

    const customColorStyle = {
        color: "#7f7f7f", // Replace with your custom color code
        '&$checked': {
            color: Maincolor, // Replace with your custom color code for checked state
        },
    };

    const langtypewise = (degree_data) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const degree_name = degree_data.map(user => ({
                label: user.degree_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));


            setDegree(degree_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const degree_name = degree_data.map(user => ({
                label: user.degree_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegree(degree_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const degree_guj = degree_data.map(user => ({
                label: user.degree_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegree(degree_guj);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const degree_name = degree_data.map(user => ({
                label: user.degree_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegree(degree_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const degree_name = degree_data.map(user => ({
                label: user.degree_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegree(degree_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const degree_name = degree_data.map(user => ({
                label: user.degree_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegree(degree_name);
        }


    }

    const langtypeinstitute = (institute) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const institute_name = institute.map(user => ({
                label: user.institute_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const institute_name = institute.map(user => ({
                label: user.institute_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const institute_name = institute.map(user => ({
                label: user.institute_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const institute_name = institute.map(user => ({
                label: user.institute_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const institute_name = institute.map(user => ({
                label: user.institute_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const institute_name = institute.map(user => ({
                label: user.institute_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setInstitute(institute_name);
        }


    }


    const location = useLocation();

    useEffect(() => {


    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const communityId = searchParams.get('communityId');
    const username = searchParams.get('username');

       
    fetchEducationItemsAdmin({
            "userId": userId,
            "communityId":communityId,
            "original": "en",
            "translate": ["en" , "en"]
        });
    
  }, [location]);


    useEffect(() => {

        setAnimated(true);

        if (data_education_admin.length > 0) {

           langtypeinstitute(data_education_admin[2].flat());
           langtypewise(data_education_admin[1].flat());

           const setup = data_education_admin[0].flat()[0];

           if(setup != undefined){

        const nextState = produce((draft) => {
     
        draft["isFromAdmin"] =   setup.is_from_admin_service;
        draft["admin_position"] =  setup.admin_position_name;
        draft["isFromIIT"] =   setup.is_from_iit_iim_nit;
        draft["iitCollege"] =   setup.college_name;
        draft["education_highest"] =   setup.education_list;
        draft["educational_details"] =   setup.education_detail;

        if(setup.is_from_admin_service == '1'){
           setIsChecked(true);
        }

        if(setup.is_from_iit_iim_nit == '1'){
             setIsCheckedIIT(true);
        }
       
    });

    // Set the updated state
    setEducation(nextState);
}
        
        }



    }, [data_education_admin]);



    const navigate = useNavigate();

   useEffect(() => {

      if(education_insert_admin.affectedRows >= 1 ||  education_update_admin.success == 1){
     setTimeout(() => {
      
        navigate(-1);
     }, 1500);

    }


   }, [education_insert_admin , education_update_admin]);


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleCheckboxChangeIIT = () => {
        setIsCheckedIIT(!isCheckedIIT);
    };

    const [educationFields, setEducation] = useState({
        isFromAdmin: '',
        admin_position: '',
        isFromIIT: '',
        iitCollege: '',
        education_highest: '',
        educational_details: '',
    });

    const [errors, setErrors] = useState({
        isFromAdmin: false,
        admin_position: false,
        isFromIIT: false,
        iitCollege: false,
        education_highest: false,
        educational_details: false,
    });


    const [hasBeenFocused, setHasBeenFocused] = useState({
        isFromAdmin: false,
        admin_position: false,
        isFromIIT: false,
        iitCollege: false,
        education_highest: false,
        educational_details: false,
    });



      const handleClick = (fieldName) => (event) => {

    const trimmedValue = event.target.value;
  

    setHasBeenFocused((prevHasBeenFocused) =>
      produce(prevHasBeenFocused, (draftHasBeenFocused) => {
        draftHasBeenFocused[fieldName] = true;
      })
    );

    //alert(hasBeenFocused[fieldName]);


    setErrors((prevErrors) =>
      produce(prevErrors, (draftErrors) => {

       // alert(trimmedValue);
       
        if(educationFields[fieldName] !== ''){
          draftErrors[fieldName] = false;
        }else{
         draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined ;
        }
        
        
      })
    );

  }

    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setEducation(nextState);
        setHasBeenFocused((prevHasBeenFocused) =>
      produce(prevHasBeenFocused, (draftHasBeenFocused) => {
        draftHasBeenFocused[fieldName] = true;
      })
    );

    setErrors((prevErrors) =>
      produce(prevErrors, (draftErrors) => {
        const trimmedValue = newValue;

        //alert(trimmedValue);
          draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '' ;
        
        
      })
    );

    };


    const handleSubmit  =  () => {


        const newErrors = produce(errors, (draftErrors) => {
          for (const field in educationFields) {
            const trimmedValue = educationFields[field];
    
              draftErrors[field] =  trimmedValue === '' || trimmedValue.length <= 0;
              draftErrors["isFromAdmin"] = false;
              draftErrors["admin_position"] = false;
              draftErrors["isFromIIT"] = false;
              draftErrors["iitCollege"] = false;

          }
        });
    
       
        setErrors(newErrors);
    
    
        // if(Object.values(newErrors).some(error=>error) == true){

            //alert(newErrors);
    
        // }else{
    


            const searchParams = new URLSearchParams(location.search);
            const userId = searchParams.get('userId');
            const communityId = searchParams.get('communityId');
            const profile_id = searchParams.get('profile_id');
    
          
          loadset(false);
          const article = {
            "is_from_admin_service": isChecked ? "1" : "0",
            "admin_position_name": educationFields.admin_position,
            "is_from_iit_iim_nit": isCheckedIIT ? "1" : "0",
            "college_name": educationFields.iitCollege == null ? "0" : educationFields.iitCollege,
            "education_list": educationFields.education_highest,
            "education_detail": educationFields.educational_details,
            "userId": userId,
            "communityId":communityId,
            "profileId": profile_id,
             "Id": data_education_admin[0].flat()[0] == undefined ?  "0" : data_education_admin[0].flat()[0].Id
          }
              
        
    
            if(data_education_admin[0].flat()[0] == undefined){
              submit_educationaldetailsAdmin(article);
            }else{
              update_educationaletailsAdmin(article);
            }
          
             
             
            
    
       // }
            
    
          
    
        
            
    
        };


    return (
        <>

{  load == false ?  <RotatingHeart/> : 
            <div style={{ position: "absolute", top: "65px", width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "60px" }}>
                <CSSTransition
                    in={true}
                    timeout={500} // Duration of the transition in milliseconds
                    classNames="fade"
                    unmountOnExit>

                    <Grid container xs={12} >

                    <Grid item xs={12}><Typography  variant="h6" align="center" width="400px" style={{padding:"5px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"10px" , marginBottom:"30px"}}>{"Edit Educational Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>


                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>

                            <Grid item xs={12} md={12}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            style={customColorStyle} // Set the color to 'primary', or use 'secondary', 'default', etc.
                                        />
                                    }
                                    label={t('education_is_ias_l')}
                                />


                            </Grid>
                            {isChecked ? <Grid item xs={12} md={12}>

                                <StyledTextField label={t('admin_position_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('admin_position')}
                                    value={educationFields.admin_position} />

                            </Grid> : ""
                            }


                            <Grid item xs={12} md={12}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isCheckedIIT}
                                            onChange={handleCheckboxChangeIIT}
                                            style={customColorStyle} // Set the color to 'primary', or use 'secondary', 'default', etc.
                                        />
                                    }
                                    label={t('is_from_iit_nit_l')}
                                />


                            </Grid>

                            {isCheckedIIT ? <Grid item xs={12} md={12} style={{marginTop:"-20px"}}>

                        

                                    <DropdownSelect  options={institute} label={t('institute_name_l')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5} 
                            onChange={handleChange('iitCollege')}
                            value={educationFields.iitCollege} />

                            </Grid> : ""
                              }

                            <Grid item xs={12} md={12} style={{marginTop:"-20px"}}>

                                <DropdownSelect options={degree} label={t('education_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('education_highest')}
                                    onMouseDown={handleClick('education_highest')}
                                    value={educationFields.education_highest} />

                                {errors.education_highest && <span style={{ color: 'red' }}>Please Select Highest Education!</span>}  

                            </Grid>

                            <Grid item xs={12} md={12}>


                             <StyledTextField label={t('education_details_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    rows={3}
                                    onChange={handleChange('educational_details')}
                                    value={educationFields.educational_details}
                                />
                                 {errors.educational_details && <span style={{ color: 'red' }}>Please add some details</span>}  

                           </Grid>


                           <Grid item xs={12} md={12}  style={{marginTop:"0px"}}>
    <Button type="button" style={{justifyContent:"center" ,alignContent:"center",
                background:"#E3256B" ,width:"100%"  , color:"white" , fontWeight:"bold" }} onClick={handleSubmit}>Submit Educational Details</Button>

                </Grid>

    




                        </Grid>

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>

                        </Grid>

                    </Grid>

                </CSSTransition>
            </div>

                            }

        </>
    );


}


export default EducationalAdmin;


